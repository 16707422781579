<template>
	<div>
		<el-tooltip class="item" effect="dark" :content="fromNow" placement="right-start">
			<small class="text-secondary">{{time}}</small>
		</el-tooltip>
	</div>
</template>
<script>
	export default{
		data(){
			return {
				time:'',
				fromNow:''
			}
		},
		props:{
			value:{
				type:String,
				default:''
			},
			format:{
				type:String,
				default:'YYYY/MM/DD HH:mm:ss'
			}
		},
		methods:{
			init(){
				this.time=this.$moment(this.value).format(this.format);
				this.fromNow=this.$moment(this.value).fromNow();
			}
		},
		created(){
			this.init();
		},
		watch:{
			value(){
				this.init();
			}
		}
	}
</script>