<template>
	<div>
		{{area}}
	</div>
</template>
<script>
	export default{
		props:{
			value:{
				type:String,
				default:''
			}
		},
		data(){
			return {
				area:[],
			}
		},
		methods:{
			init(){

				var area=[];
				if(typeof(this.value)=='string'){
					area=JSON.parse(this.value);
				}else{
					area=this.$deepCopy(this.value);
				}
				this.$post('/common/list/area',{
					where:['id','in',area],
					field:['id','shortname']
				}).then(ret=>{
					var tmp=ret.reduce((prev,curr)=>{
						prev.push(curr['shortname']);
						return prev;
					},[]);
					this.area=tmp.join(' / ');
				})
			}
		},
		created(){
			this.init();
		},
		watch:{
			value(){
				this.init();
			}
		}
	}
</script>