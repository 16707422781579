<template>
	<div class="overflow-hidden" :style="{height:height}">
		<el-row :gutter="10" class="yp_uploader_row">
			<div v-if="multiple">
				<draggable v-model="pics" :options="{animation: 300,handle:'.el-icon-rank'}" @update="submit">
					<el-col :span="24/col" v-for="(item,index) in pics" :key="index">
						<div class="yp_uploader_preview">
							<el-image class="yp_uploader_preview_image" :fit="fit" :src="cdn+item+'/w500'" v-if="!isVideo(item)"></el-image>
							<el-image :src="item|videoCover(1000,2000)" class="yp_uploader_preview_image" :fit="fit" v-if="isVideo(item)"></el-image>
							<div class="yp_uploader_cover" v-if="index==0&&cover">封面</div>
							<div class="yp_uploader_preview_action">
								<i class="el-icon-rank"></i>
								<i class="el-icon-delete" @click="delPic(index)"></i>
							</div>
						</div>
					</el-col>
				</draggable>
			</div>
			<el-col :span="24/col">
				<el-upload :multiple="multiple" :accept="accept" :show-file-list="false" :before-upload="beforeUpload" :action="upConf['url']" :data="upConf['data']" :on-success="uploadSuccess" :on-progress="uploadProgress" ref="uploader" class="yp_uploader">
					<div class="yp_uploader_action">
						<i class="el-icon-upload2" style="font-size: 18px;"></i>
						<div class="pt-2">{{placeholder}}</div>
					</div>
					<el-image :src="cdn+pics+'/w500'" class="yp_uploader_preview_image" :fit="fit" v-if="pics&&!multiple&&!isVideo(pics)"></el-image>
					<el-image :src="pics|videoCover(1000,2000)" class="yp_uploader_preview_image" :fit="fit" v-if="pics&&!multiple&&isVideo(pics)"></el-image>
					<div class="yp_uploader_mask" v-if="pics&&!multiple">
						<i class="el-icon-upload2"></i>
						<div>重新上传</div>
					</div>
				</el-upload>
			</el-col>
		</el-row>
		<!-- <el-row>
			<el-col :span="6">
				预览
			</el-col>
			<el-col :span="6">
				<el-upload class="yp_uploader" :multiple="multiple" :accept="accept" :show-file-list="false" :before-upload="beforeUpload" :action="upConf['url']" :data="upConf['data']" :on-success="uploadSuccess" :on-progress="uploadProgress" :style="{width:width}" ref="uploader">
					<div v-if="preview">
						<div class="action" v-if="!value">
							<i class="el-icon-upload2"></i>
							<div>点击上传</div>
						</div>
						<div class="preview" v-if="value">
							<el-image :src="cdn+value+'/w600'" :fit="fit" v-if="!isVideo"></el-image>
							<el-image :src="cdn+value+'?x-oss-process=video/snapshot,t_3000,f_jpg,w_800,h_0,m_fast'" :fit="fit" v-if="isVideo"></el-image>
						</div>
						<div class="mask" @click.stop="noUp" v-if="value">
							<i class="el-icon-refresh" @click="click"></i>
							<i class="el-icon-delete" @click="del"></i>
						</div>
					</div>
					<slot></slot>
				</el-upload>
			</el-col> -->
		</div>
	</template>
	<style scoped>
	@import './index.css';
</style>
<script>
	export default{
		data(){
			return {
				height:'auto',
				pics:'',//数据,
				upConf:{
					url:'',
					height:'auto'
				}
			}
		},
		props:{
			value:{
				type:null,
				default:''
			},
			multiple:{
				type:Boolean,
				default:false
			},
			accept:{
				type:String,
				default:'*'
			},
			fit:{
				type:String,
				default:'cover'
			},
			preview:{
				type:Boolean,
				default:false
			},
			col:{
				type:Number,
				default:4
			},
			cover:{
				type:Boolean,
				default:false
			},
			placeholder:{
				type:String,
				default:'点击上传'
			}
		},
		created(){
			if(this.multiple){
				if(!this.value){
					this.pics=[];
					this.submit();
				}else{
					this.pics=JSON.parse(this.value);
				}
			}else{
				this.pics=this.value;
			}
			if(!this.preview){
				this.width=0;
				this.height=0;
			}else{

			}
		},
		methods:{
			delPic(idx){
				this.pics.splice(idx,1);
				this.submit();
				this.$forceUpdate();
			},
			submit(){
				//提交内容
				if(this.multiple){
					this.$emit('input',JSON.stringify(this.pics));
				}else{
					this.$emit('input',this.pics);
				}
			},
			noUp(){
				return false;
			},
			beforeUpload(file){
				var keyName='file/'+this.uuidv4()+file.name.substr(file.name.lastIndexOf("."));
				let promise=new Promise((resolve)=>{
					this.$post('/common/upload/conf').then(res=>{
						this.upConf=res;
						this.upConf['data']['key']=keyName;
						file.fileName=keyName;
						if(file.type.includes('video')||file.type.includes('audio')){
							var fileurl=URL.createObjectURL(file);
							var audioElement = new Audio(fileurl);
							audioElement.addEventListener("loadedmetadata", function () {
								file.duration=audioElement.duration;
								resolve(true);
							});
						}else{
							resolve(true);
						}
					})
				})
				return promise;	
			},
			isVideo(file){
				var suffix=file.substr(file.lastIndexOf("."));
				var videoArr=['avi','mp4','mov','mpeg','rmvb','mpeg','m4v'];
				return videoArr.includes(suffix.toLowerCase().replace('.',''));
			},
			uploadSuccess(res,file,fileList){
				var info={
					name:file.name,
					url:file.raw.fileName,
					type:file.raw.type,
					size:file.size,
					duration:file.raw.duration||0
				}
				var filename=file.raw.fileName;
				if(this.multiple){
					this.pics.push(filename);
				}else{
					this.pics=filename;
				}
				this.$forceUpdate();
				this.submit();
				this.$emit('success',filename,info,file);
			},
			uploadProgress(event, file, fileList){
				this.$emit('progress',event, file, fileList);
			},
			click(){
				return this.$el.querySelector('input').click();
			},
			del(){
				this.$emit('input','');
			}
		},
		watch:{
			value(val){
				if(this.multiple){
					if(!this.value){
						this.pics=[];
						this.submit();
					}else{
						this.pics=JSON.parse(this.value);
					}
				}else{
					this.pics=this.value;
				}
			}
		}
	}
</script>