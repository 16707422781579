<template>
	<div>
		<el-cascader v-model="area" :placeholder="placeholder" :options="options" filterable
			:props="{ expandTrigger: 'hover' }"></el-cascader>
	</div>
</template>
<script>
export default {
	props: {
		value: {
			type: null,
			default: ''
		},
		placeholder: {
			type: String,
			default: '请选择'
		},
		level: {
			type: Number,
			default: 3
		}
	},
	data() {
		return {
			options: [],
			area: []
		}
	},
	methods: {
		makeTrees(category, pid = 0) {
			const ret = [];
			category.forEach(item => {
				if (item['pid'] == pid) {
					const children = this.makeTrees(category, item['id']);
					const obj = Object.assign({}, item);
					obj['label'] = item['shortname'];
					obj['value'] = item['id'];
					if (children.length) {
						obj.children = children;
					}
					ret.push(obj);
				}
			})
			return ret;
		},
		update() {
			this.$post('/common/list/area', {
				field: ['shortname', 'id', 'pid', 'level']
			}).then(ret => {
				ret = ret.filter(item => item.level <= this.level);
				this.options = this.makeTrees(ret);
			})
		},
		init() {
			if (this.value) {
				this.area = JSON.parse(this.value).map(item => parseInt(item));
			} else {
				this.area = [];
			}
		}
	},
	created() {
		this.init();
		this.update();
	},
	watch: {
		value(val) {
			this.init();
		},
		area(val) {
			var data = val.map(item => item + '')
			if (data != this.value) {
				this.$emit('input', JSON.stringify(data));
			}
		}
	}
}
</script>