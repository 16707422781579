<template>
	<span class="yp_status" :type="type">
		<slot></slot>
	</span>
</template>
<style scoped>
@import './index.css';
</style>
<script>
	export default{
		props:{
			type:{
				type:String,
				default:''
			}
		}
	}
</script>