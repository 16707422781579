import axios from 'axios';
import Element from 'element-ui';
import storage from 'good-storage';
const instance = axios.create({
	baseURL: 'https://api.qipengit.com/',
	headers: {
		'content-type': 'application/json'
	},
	timeout: 60000
});
// request拦截器 ==> 对请求参数进行处理
instance.interceptors.request.use(
	config => {
		//TOKEN
		var token=storage.get('access_token');
		config.headers.Authorization = `Basic ${token}`;
		return config;
	}, error => {
		console.log(error);
		Promise.reject(error);
	}
	);

// respone拦截器 ==> 对响应做处理
instance.interceptors.response.use(
	response => {
		const res = response.data;
		if(res['message']){
			Element.Message({
				message: res['message'],
				type: "success"
			});
		}
		return res;
	},
	error=>{
		if(!error.response){
			//需要重新登录
			Element.Message({
				message: '网络连接错误，请稍等再试',
				type: "error"
			});
			return false;
		}
		var message='';
		if (error.response.status) {
			var code=error.response.status;
			if(code==400){
				message=error.response.data;
			}
			if(code==401){
				//需要重新登录
				storage.remove('access_token');
				window.location.href = "/login";
				return false;
			}
			if(code==403){
				//无权访问
				return false;
			}
		}else{
			message='网络连接失败，请重试';
		}
		Element.Message({
			message: message,
			type: "error"
		});
		return Promise.reject('');
	}
	);
export default instance;