<template>
	<span>{{numberText()}}</span>
</template>
<script>
	export default{
		name:'Countup',
		data(){
			return {
				number:0,
				timer:''
			}
		},
		props:{
			value:{
				type:Number,
				default:0
			},
			qianfen:{
				type:Boolean,
				default:true
			}
		},
		methods:{
			init(){
				clearTimeout(this.timer);
				var timeStep=20;
				var times=50;
				var diff=this.value-this.number
				var step=Math.ceil(diff/times);
				this.countTo(this.number,this.value,step,timeStep);
			},
			countTo(from,to,step,timeStep){
				if(this.number+step>=to){
					this.number=to;
				}else{
					this.number+=step;
					this.timer=setTimeout(()=>{
						this.countTo(from,to,step,timeStep);
					},timeStep);
				}
			},
			numberText(){
				if(this.qianfen){
					return this.number.toLocaleString();
				}else{
					return this.number;
				}
			}
		},
		watch:{
			value(val){
				this.init();
			}
		},
		created(){
			this.init();
		}
	}
</script>