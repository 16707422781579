class MyUploadAdapter {
    constructor(loader, that) {
        this.loader = loader;
        this.that = that;
    }
    async upload() {
        var url=await this.that.$ossUpload2(await this.loader.file);
        return {
            default: this.that.cdn+url,
        };
    }
}
export default MyUploadAdapter;