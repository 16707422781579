import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

//引入ElementUI
import Element from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element,{size:'small'})

// //路由配置
import router from './router'

// //Vuex配置
import store from './store'
Vue.prototype.$store = store

//Axios
import axios from './utils/axios';
Vue.prototype.$post=axios.post;
Vue.prototype.$get=axios.get;

//localStorage
import storage from 'good-storage';
Vue.prototype.$storage=storage;

//Bootstrap
import './assets/css/bootstrap.css';

//Global
import './assets/css/global.css'
import Utils from './utils';
Vue.use(Utils);

//拖动排序组件
import draggable from 'vuedraggable';
Vue.component('draggable', draggable);
import Sortable from 'sortablejs'
window.Sortable=Sortable;

//Moment时间插件
import Moment from 'moment'
Moment.locale('zh-cn');//设置为中文
Vue.prototype.$moment = Moment

//uuidv4
import { v4 as uuidv4 } from 'uuid';
Vue.prototype.uuidv4=uuidv4;

//文件上传组件
import Uploader from './components/Upload';
Vue.use(Uploader);

//TimeBar时间显示
import TimeBar from './components/Timebar'
Vue.use(TimeBar);

//二维码组件
import vueQr from 'vue-qr'
Vue.use(vueQr);

//状态文字组件
import Status from './components/Statustext'
Vue.use(Status);

//区域选择
import AreaSelect from './components/Areaselect'
Vue.use(AreaSelect);

//区域显示 
import AreaView from './components/Areaview'
Vue.use(AreaView);

//分类显示
import CategoryView from './components/Categoryview'
Vue.use(CategoryView);

//分类显示
import Countup from './components/Countup'
Vue.use(Countup);

// //图片裁切插件
Vue.use(window['vue-cropper'].default);

import Cropper from './components/Cropper'
Vue.use(Cropper);

//图标先把
import Iconselector from './components/Iconselector'
Vue.use(Iconselector);

// //富文本编辑器
// // import VueQuillEditor from 'vue-quill-editor'
// // import 'quill/dist/quill.core.css';
// // import 'quill/dist/quill.snow.css';
// // import 'quill/dist/quill.bubble.css';
// // Vue.use(VueQuillEditor)


//ckeditor
import ckeditor from './components/ckeditor'
Vue.use(ckeditor);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
