<template>
	<div>
		{{category}}
	</div>
</template>
<script>
	export default{
		props:{
			value:{
				type:String,
				default:''
			}
		},
		data(){
			return {
				category:[],
			}
		},
		methods:{
			init(){
				var category=[];
				if(typeof(this.value)=='string'){
					category=JSON.parse(this.value);
				}else{
					category=this.$deepCopy(this.value);
				}
				this.$post('/common/list/category',{
					where:['id','in',category],
					field:['id','name']
				}).then(ret=>{
					var tmp=ret.reduce((prev,curr)=>{
						prev.push(curr['name']);
						return prev;
					},[]);
					this.category=tmp.join(' / ');
				})
			}
		},
		created(){
			this.init();
		},
		watch:{
			value(){
				this.init();
			}
		}
	}
</script>