var Utils = {};
import moment from 'moment';
Utils.install = function (Vue) {
	//CDN域名
	Vue.prototype.cdn = 'https://cdn.qipengit.com/';
	//输出错误信息并获取焦点
	Vue.prototype.$errTips = function (msg, ref) {
		this.$message.warning(msg);
		if (!ref) {
			return false;
		}
		var diyRef = false;
		var classList;
		if (this.$refs[ref].$el) {
			classList = this.$refs[ref].$el.classList;
		} else {
			diyRef = true;
			classList = this.$refs[ref][0].$el.classList;
		}
		classList = Array.from(classList);
		if (classList.includes('el-input')) {
			diyRef ? this.$refs[ref][0].focus() : this.$refs[ref].focus();
			return false;
		}
		if (classList.includes('el-textarea')) {
			this.$refs[ref].focus();
			return false;
		}
		if (classList.includes('el-select')) {
			this.$refs[ref].toggleMenu();
			return false;
		}
		if (classList.includes('el-cascader')) {
			this.$refs[ref].toggleDropDownVisible();
			return false;
		}
		return false;
	}
	//数组、对象深拷贝
	Vue.prototype.$deepCopy = function (obj) {
		// 只拷贝对象
		if (typeof obj !== 'object') return;
		// 根据obj的类型判断是新建一个数组还是一个对象
		var newObj = obj instanceof Array ? [] : {};
		for (var key in obj) {
			// 遍历obj,并且判断是obj的属性才拷贝
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				// 判断属性值的类型，如果是对象递归调用深拷贝
				if (obj[key] == null) {
					newObj[key] = "";
				} else {
					newObj[key] = typeof obj[key] === 'object' ? this.$deepCopy(obj[key]) : obj[key];
				}
			}
		}
		return newObj;
	}
	//拷贝对象的部分参数
	Vue.prototype.$pick = function () {
		var obj = arguments[0];
		var params;
		if (typeof (arguments[1]) == 'object') {
			params = arguments[1];
		} else {
			params = Array.from(arguments);
			params.shift();
		}
		return params.reduce((iter, val) => {
			if (val in obj) {
				iter[val] = obj[val] == null ? '' : obj[val];
			}
			return iter;
		}, {});
	}
	//复制到剪贴板
	Vue.prototype.$copy = function (content) {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(content)
				.then(() => {
					this.$message.success('复制成功');
				})
				.catch((error) => {
					this.$message.error('复制失败');
				});
			return true;
		} else {
			this.$message.error('复制失败');
			return false;
		}
		// if (document.execCommand) {
		// 	var input = document.createElement('input');
		// 	input.classList.add('clipboardInput');
		// 	document.body.appendChild(input);
		// 	input.value = content;
		// 	input.select();
		// 	document.execCommand("Copy");
		// 	input.remove();
		// 	this.$message.success('复制成功');
		// 	return true;
		// } else if (window.clipboardData) {
		// 	window.clipboardData.setData("Text", content);
		// 	this.$message.success('复制成功');
		// 	return true;
		// } else {
		// 	this.$message.error('复制失败');
		// 	return false;
		// }
	}
	//控件获取焦点
	Vue.prototype.$autoFocus = function (ref) {
		setTimeout(() => {
			var diyRef = false;
			var classList;
			if (this.$refs[ref].$el) {
				classList = this.$refs[ref].$el.classList;
			} else {
				diyRef = true;
				classList = this.$refs[ref][0].$el.classList;
			}

			classList = Array.from(classList);
			this.$nextTick(() => {
				if (classList.includes('el-input')) {
					diyRef ? this.$refs[ref][0].focus() : this.$refs[ref].focus();
				}
				if (classList.includes('el-textarea')) {
					diyRef ? this.$refs[ref][0].focus() : this.$refs[ref].focus();
				}
				if (classList.includes('el-select')) {
					this.$refs[ref].toggleMenu();
				}
			})
		}, 100)
	}
	//通用删除功能
	Vue.prototype.$delete = function (table, id, cb) {
		this.$confirm('你确定要删除这条数据吗', '操作提示').then(() => {
			var data = { id: id, status: 0 };
			this.$post('/common/save/' + table, data).then(() => {
				this.update ? this.update() : '';
				cb ? cb() : '';
				this.$post('/common/addLog', {
					content: {
						type: 'del_' + table,
						bindId: id
					}
				}).then(ret => {

				})
			})
		})
	}
	//下载文件
	Vue.prototype.$download = function (file, filename = '') {
		if (!filename) {
			filename = file.substring(file.lastIndexOf("/") + 1, file.length)
		}
		this.$post('/common/download', { file: file, filename: filename }).then(ret => {
			var ifr = document.createElement('iframe');
			ifr.style.display = 'none';
			document.body.appendChild(ifr);
			ifr.src = ret;
			ifr.onload = function () {
				document.body.removeChild(ifr);
				ifr = null;
			}
		})
	}
	//全屏
	Vue.prototype.$fullScreen = function () {
		if (document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
			else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			}
			else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
			else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			var cfs = window.cfs;
			if (typeof cfs != "undefined" && cfs) {
				cfs.call(el);
			}
		} else {
			var el = document.documentElement;
			var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
			if (typeof rfs != "undefined" && rfs) {
				rfs.call(el);
			};
		}
	}
	Vue.filter('telView', function (tel) {
		return tel.substr(0, 3) + ' **** ' + tel.substr(tel.length - 4, 4);
	})
	Vue.filter('telView2', function (tel) {
		return tel.replace(/^(.{3})(.*)(.{4})$/, '$1 $2 $3');
	})
	Vue.filter('videoCover', function (src, width = 200, t = 2000) {
		console.log('https://cdn.qipengit.com/' + src + '?x-oss-process=video/snapshot,t_' + t + ',f_jpg,w_' + width + ',h_0,m_fast');
		return 'https://cdn.qipengit.com/' + src + '?x-oss-process=video/snapshot,t_' + t + ',f_jpg,w_' + width + ',h_0,m_fast';
	})
	Vue.filter('filesize', function (size) {
		var data = "";
		if (size < 0.1 * 1024) {
			data = size.toFixed(2) + " B";
		} else if (size < 0.1 * 1024 * 1024) {
			data = (size / 1024).toFixed(2) + " KB";
		} else if (size < 0.1 * 1024 * 1024 * 1024) {
			data = (size / (1024 * 1024)).toFixed(2) + " MB";
		} else {
			data = (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
		}
		var sizestr = data + "";
		var len = sizestr.indexOf("\.");
		var dec = sizestr.substr(len + 1, 2);
		if (dec == "00") {
			return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
		}
		return sizestr;
	})
	Vue.filter('fileType', function (name) {
		var types = [{
			name: '设计源文件',
			prefix: ['psd']
		}, {
			name: 'Ppt文档',
			prefix: ['ppt', 'pptx']
		}, {
			name: 'Word文档',
			prefix: ['doc', 'docx']
		}, {
			name: 'Excel文档',
			prefix: ['xlsx', 'xlsx']
		}, {
			name: '图片文件',
			prefix: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tif']
		}, {
			name: '音频文件',
			prefix: ['mp3', 'mpeg', 'wama', 'aac', 'midi', 'mod', 'wave']
		}, {
			name: '视频文件',
			prefix: ['mp4', 'mov', 'avi', 'rmvb', 'rm', 'flv', '3gp']
		}, {
			name: '压缩文件',
			prefix: ['zip', 'rar', '7z', 'tar']
		}]
		var prefix = name.substr(name.lastIndexOf(".") + 1);
		var type = types.find(item => {
			return item['prefix'].includes(prefix.toLowerCase())
		})
		if (!type) {
			return '其它文件';
		} else {
			return type.name;
		}
	})
	//手动上传文件到OSS
	Vue.prototype.$ossUpload = function (file, cb) {
		var that = this;
		var fileName = 'file/' + this.uuidv4() + file.name.substr(file.name.lastIndexOf("."));
		this.$post('/common/upload/conf').then(res => {
			var form = new FormData();
			form.append('OSSAccessKeyId', res['data']['OSSAccessKeyId']);
			form.append('policy', res['data']['policy']);
			form.append('Signature', res['data']['Signature']);
			form.append('key', fileName);
			form.append('file', file);;
			var xhr = new XMLHttpRequest();
			xhr.onreadystatechange = function () {
				if (xhr.readyState == 4) {
					cb(fileName);
				}
			}
			xhr.open("post", res['url'], true);
			xhr.send(form);
		})
	}
	//手动上传文件到OSS
	Vue.prototype.$ossUpload2 = function (file) {
		var that = this;
		var fileName = 'file/' + this.uuidv4() + file.name.substr(file.name.lastIndexOf("."));
		return new Promise((resolve) => {
			this.$post('/common/upload/conf').then(res => {
				var form = new FormData();
				form.append('OSSAccessKeyId', res['data']['OSSAccessKeyId']);
				form.append('policy', res['data']['policy']);
				form.append('Signature', res['data']['Signature']);
				form.append('key', fileName);
				form.append('file', file);;
				var xhr = new XMLHttpRequest();
				xhr.onreadystatechange = function () {
					if (xhr.readyState == 4) {
						resolve(fileName);
					}
				}
				xhr.open("post", res['url'], true);
				xhr.send(form);
			})
		})
	}
	Vue.prototype.$verify = {
		int: function (str) {
			return /(^[1-9]\d*$)/.test(str);
		},
		tel: function (str) {
			return /^1[3456789]\d{9}$/.test(str);
		},
		idCard: function (str) {
			return /^\\d{6}(18|19|20)?\\d{2}(0\[1-9\]|1\[12\])(0\[1-9\]|\[12\]\\d|3\[01\])\\d{3}(\\d|X)$/.test(str);
		},
		email: function () {
			return /^\\w+(\[-+.\]\\w+)\*@\\w+(\[-.\]\\w+)\*\\.\\w+(\[-.\]\\w+)\*$/.test(str);
		},
		chinese: function (str) {
			return /^\[\\u0391-\\uFFE5\]+$/.test(str);
		}
	}
	Vue.prototype.$jump = function (url) {
		this.$router.push({ path: url });
	}
	Vue.prototype.$openLink = function (url) {
		window.open(url);
	}
	Vue.filter('time2date', function (str, format = "YYYY年MM月DD日 HH:mm:ss") {
		return moment(str).format(format);
	})
	//执行批量任务
	Vue.prototype.batchTask = async function (tasks) {
		return new Promise(async (resolve) => {
			var results = [];
			for (const task of tasks) {
				results.push(await task());
			}
			resolve(results);
		})
	}
}
export default Utils;