<template>
    <div>
        <div class="placeholder" @click="$refs['editor'].focus()" v-if="showPlace">
            <div>在这里输入内容</div>
        </div>
        <div id="editor" ref="editor"></div>
    </div>
</template>
<script>
import MyUploadAdapter from "./ckEditorUp";
export default {
    props: ['value'],
    data() {
        return {
            types: ['primary', 'success', 'warning', 'danger', 'info'],
            content: '',
            editor: '',
            focused: false
        }
    },
    mounted() {
        this.content = this.value;
        this.$emit('wordcount', this.wordCount);
        var editor = BalloonEditor
            .create(document.querySelector('#editor'))
            .then(editor => {
                editor.setData(this.content);
                editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                    return new MyUploadAdapter(loader, this);
                };
                editor.model.document.on('change:data', () => {
                    this.content = editor.getData();
                });
                editor.editing.view.document.on('change:isFocused', (evt, name, value) => {
                    this.focused = value;
                });
                editor.placeholder = '123';
                this.editor = editor;
            })
            .catch(error => {
                console.error(error);
            });
    },
    methods: {
        focus() {
            return this.$refs['editor'].focus();
        },
        cancel() {
            this.$confirm('您确定要放弃保存吗？').then(() => {
                this.$router.push('/createTable');
            })
        },
        addKeywords() {
            this.$prompt('请输入关键词').then(({ value }) => {
                this.form.keywords.push(value);
            }).catch(() => {
            });
        },
        removeKeywords(index) {
            this.form.keywords.splice(index, 1);
        },
        toText(HTML) {
            var input = HTML;
            return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ');
        },
    },
    computed: {
        wordCount() {
            return this.toText(this.content).length;
        },
        showPlace() {
            return this.content.length == 0 && !this.focused;
        }
    },
    watch: {
        content(val) {
            if (val != this.value) {
                this.$emit('input', val);
                this.$emit('wordcount', this.wordCount);
            }
        }
    }
}
</script>

<style scoped>
#editor {
    font-size: 14px;
    min-height: calc(100vh - 400px);
    padding: 0;
    border: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid #f1f2f3 !important;
}

.placeholder {
    position: relative;
    cursor: text;
}

.placeholder>div {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 14px;
    color: #a3a3a3;
}
</style>