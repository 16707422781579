<template>
	<div>
		<el-dialog title="图片裁剪" :visible.sync="showDialog" width="600px" :close-on-click-modal="false"
			:close-on-press-escape="false" :modal-append-to-body="false">
			<div style="width: 100%;height: 400px;" v-if="showDialog">
				<vue-cropper ref="cropper" :img="option.img" :output-size="option.size" :output-type="option.outputType"
					:info="true" :full="option.full" :fixed="fixed" :fixed-number="fixedNumber" :can-move="option.canMove"
					:can-move-box="option.canMoveBox" :fixed-box="option.fixedBox" :original="option.original"
					:auto-crop="option.autoCrop" :auto-crop-width="option.autoCropWidth"
					:auto-crop-height="option.autoCropHeight" :center-box="option.centerBox" :high="option.high"
					mode="contain" :max-img-size="option.max"></vue-cropper>
			</div>
			<div slot="footer">
				<div class="float-left">
					<el-button @click="rotateLeft" type="warning" plain icon="el-icon-refresh-left">向左旋转</el-button>
					<el-button @click="rotateRight" type="danger" plain icon="el-icon-refresh-right">向右旋转</el-button>
				</div>
				<el-button @click="submit" icon="el-icon-check" type="primary">完成</el-button>
				<el-button @click="cancel" icon="el-icon-close">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<style scoped>
@import './index.css';
</style>
<script>
export default {
	props: {
		value: {
			type: String,
			default: 'https://avatars2.githubusercontent.com/u/15681693?s=460&v=4'
		},
		show: {
			type: Boolean,
			default: false
		},
		height: {
			type: Number,
			default: 600
		},
		width: {
			type: Number,
			default: 400
		}
	},
	data() {
		return {
			showDialog: false,
			option: {
				img: 'https://avatars2.githubusercontent.com/u/15681693?s=460&v=4',
				size: 1,
				full: false,
				outputType: 'png',
				canMove: false,
				canScale: false,
				fixedBox: false,
				original: false,
				canMoveBox: true,
				autoCrop: true,
				centerBox: true,
				high: true,
				max: 99999
			},
			fixed: true,
			fixedNumber: [3, 2]
		}
	},
	methods: {
		rotateLeft() {
			this.$refs.cropper.rotateLeft();
		},
		rotateRight() {
			this.$refs.cropper.rotateRight();
		},
		changeScale(num) {
			num = num || 1
			this.$refs.cropper.changeScale(num)
		},
		submit() {
			this.$refs['cropper'].getCropBlob(data => {
				let file = new File([data], '图片文件.png');
				this.$ossUpload(file, (ret => {
					this.$emit('input', ret);
					this.$emit('success', ret);
					this.$emit('close');
				}))
			})
		},
		cancel() {
			this.$emit('close');
		}
	},
	watch: {
		value() {
			this.option.img = this.cdn + this.value;
		},
		show(val) {
			this.showDialog = this.show ? true : false;
		},
		showDialog(val) {
			if (val) {

			} else {
				this.$emit('close');
			}
		}
	},
	created() {
		this.option.img = this.cdn + this.value;
		this.option.autoCropWidth = this.width;
		this.option.autoCropHeight = this.height;
		this.fixedNumber = [this.width, this.height];
	}
}
</script>