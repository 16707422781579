import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from '../utils/axios';
Vue.use(VueRouter)
function getRoutes() {
	return new Promise((resolve) => {
		axios.post('/').then(ret => {
			store.commit('update', ['isLoaded', true]);
			store.commit('update', ['user', ret['user']]);
			store.commit('update', ['organization', ret['organization']]);
			store.commit('update', ['menus', JSON.parse(JSON.stringify(ret['menus']))]);
			resolve(ret['menus']);
		})
	})
}
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [{
		path: '*',
		component: () => import('../views/common/404.vue')
	}, {
		path: '/login',
		component: () => import('../views/common/login.vue')
	}]
})
router.beforeEach((to, from, next) => {
	if (to.path == '/login') {
		next();
		return false;
	}
	if (!store.state.isLoaded) {
		getRoutes().then(ret => {
			var routes = ret.filter(item => item['path']);
			routes = routes.map(item => {
				var component = item['component'];
				item['component'] = () => import('../views' + component + '.vue');
				if (item['name'] == '控制面板') {
					item.path = '';
				}
				return item;
			})
			var homeRoute = routes.find(item => item['path'] == '/');
			homeRoute['children'] = routes.filter(item => item['path'] != '/');
			router.addRoutes([homeRoute]);
			router.push({ path: to.path, query: to.query })
		})
	} else {
		next();
	}
})
export default router