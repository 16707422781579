<template>
	<div>
		<el-dialog title="图标选择" :visible.sync="visible" width="600px" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
			<el-row class="iconRows">
				<el-col :span="3" v-for="item in icons">
					<div class="iconItem" @click="selectIcon(item)">
						<i :class="'icon '+item"></i>
					</div>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>
<style scoped>
.iconRows>.el-col-3{
	margin-left: -1px;
	margin-top: -1px;
}
.iconItem{
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	border:1px solid #f5f6f7;
	cursor: pointer;
}
.iconItem>.icon{
	font-size: 22px;
}
.iconItem:hover{
	position: relative;
	z-index: 2;
	border-color: #eee;
	background: #f5f6f7;
}
</style>
<script>
	export default{
		props:{
			first:{
				type:Object,
				default:[]
			}
		},
		data(){
			return {
				visible:false,
				icons:[],
				cssContent:`@font-face {
					font-family: "icon"; /* Project id 2436259 */
					src: url('//at.alicdn.com/t/font_2436259_4ubnh6kb8nm.woff2?t=1642748120334') format('woff2'),
					url('//at.alicdn.com/t/font_2436259_4ubnh6kb8nm.woff?t=1642748120334') format('woff'),
					url('//at.alicdn.com/t/font_2436259_4ubnh6kb8nm.ttf?t=1642748120334') format('truetype');
				}

				.icon {
					font-family: "icon" !important;
					font-size: 16px;
					font-style: normal;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}

				.i-xiaochengxu2:before {
					content: "\e619";
				}

				.i-gongzhonghao:before {
					content: "\e654";
				}

				.i-tianjiawendang:before {
					content: "\e630";
				}

				.i-xianxingchumotubiao:before {
					content: "\e674";
				}

				.i-home1:before {
					content: "\e61e";
				}

				.i-daima1:before {
					content: "\e614";
				}

				.i-daba:before {
					content: "\e6fa";
				}

				.i-bisaihuodong:before {
					content: "\e613";
				}

				.i-kafei:before {
					content: "\e78c";
				}

				.i-qiziqiqizhizhibiao:before {
					content: "\e612";
				}

				.i-file2:before {
					content: "\e611";
				}

				.i-fengjing:before {
					content: "\e625";
				}

				.i-daocha:before {
					content: "\e604";
				}

				.i-koushao:before {
					content: "\e60e";
				}

				.i-banchedaba:before {
					content: "\e680";
				}

				.i-shatan:before {
					content: "\f52d";
				}

				.i-a-tupianyihuifu-20:before {
					content: "\e60f";
				}

				.i-rili:before {
					content: "\e602";
				}

				.i-rili1:before {
					content: "\e603";
				}

				.i-rili2:before {
					content: "\e607";
				}

				.i-rili3:before {
					content: "\e608";
				}

				.i-rili4:before {
					content: "\e609";
				}

				.i-rili5:before {
					content: "\e60a";
				}

				.i-rili6:before {
					content: "\e60b";
				}

				.i-rili8:before {
					content: "\e60c";
				}

				.i-rili10:before {
					content: "\e60d";
				}

				.i-rili13:before {
					content: "\e610";
				}

				.i-tubiao:before {
					content: "\e606";
				}

				.i-split-screen-compare:before {
					content: "\e86a";
				}

				.i-shangyinhao:before {
					content: "\e63d";
				}

				.i-loading:before {
					content: "\e61d";
				}

				.i-loading1:before {
					content: "\e6da";
				}

				.i-loading2:before {
					content: "\e730";
				}

				.i-Loading-copy:before {
					content: "\eb1b";
				}

				.i-ppt:before {
					content: "\e63f";
				}

				.i-bumen:before {
					content: "\e64e";
				}

				.i-cardid:before {
					content: "\e621";
				}

				.i-xingmingyonghumingnicheng:before {
					content: "\e623";
				}

				.i-xingbie:before {
					content: "\e62d";
				}

				.i-yuangonggonghao:before {
					content: "\e6e4";
				}

				.i-tubiaomoban-:before {
					content: "\e719";
				}

				.i-tuodong:before {
					content: "\e6ff";
				}

				.i-location:before {
					content: "\e634";
				}

				.i-sousuo:before {
					content: "\e653";
				}

				.i-OCR:before {
					content: "\e694";
				}

				.i-cheliang:before {
					content: "\e696";
				}

				.i-jiaose:before {
					content: "\e640";
				}

				.i-daima:before {
					content: "\e6b9";
				}

				.i-arr-left:before {
					content: "\e601";
				}

				.i-canshu:before {
					content: "\eb1a";
				}

				.i-collapse:before {
					content: "\e605";
				}

				.i-collapse-copy:before {
					content: "\ead1";
				}

				.i-xiaochengxu:before {
					content: "\e600";
				}

				.i-xiaochengxu1:before {
					content: "\e644";
				}

				.i-gengduo:before {
					content: "\e689";
				}

				.i-logout:before {
					content: "\e89a";
				}

				.i-user-add-Fill:before {
					content: "\e990";
				}

				.i-steak:before {
					content: "\ea90";
				}

				.i-user-minus-Fill:before {
					content: "\e991";
				}

				.i-superscript:before {
					content: "\ea91";
				}

				.i-vidicon-download-Fill:before {
					content: "\e992";
				}

				.i-shop:before {
					content: "\ea92";
				}

				.i-vidicon-Fill:before {
					content: "\e993";
				}

				.i-toys1:before {
					content: "\ea93";
				}

				.i-vidicon-minus-Fill:before {
					content: "\e994";
				}

				.i-star:before {
					content: "\ea94";
				}

				.i-user-group-Fill:before {
					content: "\e995";
				}

				.i-trophy:before {
					content: "\ea95";
				}

				.i-user-right-Fill:before {
					content: "\e996";
				}

				.i-tariff:before {
					content: "\ea96";
				}

				.i-vidicon-upload-Fill:before {
					content: "\e997";
				}

				.i-textile:before {
					content: "\ea97";
				}

				.i-wallet-Fill:before {
					content: "\e998";
				}

				.i-survey:before {
					content: "\ea98";
				}

				.i-voice-Fill:before {
					content: "\e999";
				}

				.i-direction:before {
					content: "\ea99";
				}

				.i-voice-off-Fill:before {
					content: "\e99a";
				}

				.i-Sort:before {
					content: "\ea9a";
				}

				.i-volume-down-Fill:before {
					content: "\e99b";
				}

				.i-time:before {
					content: "\ea9b";
				}

				.i-volume-up-Fill:before {
					content: "\e99c";
				}

				.i-ticket:before {
					content: "\ea9c";
				}

				.i-Warehouse-fill:before {
					content: "\e99d";
				}

				.i-trendchart:before {
					content: "\ea9d";
				}

				.i-volume-mute-Fill:before {
					content: "\e99e";
				}

				.i-trash:before {
					content: "\ea9e";
				}

				.i-wheelbarrow-Fill:before {
					content: "\e99f";
				}

				.i-Double-Down:before {
					content: "\ea9f";
				}

				.i-user-Fill:before {
					content: "\e9a0";
				}

				.i-tag:before {
					content: "\eaa0";
				}

				.i-warning-Fill:before {
					content: "\e9a1";
				}

				.i-underline:before {
					content: "\eaa1";
				}

				.i-trendchart-Fill:before {
					content: "\e9a2";
				}

				.i-twitter:before {
					content: "\eaa2";
				}

				.i-vidicon-add-Fill:before {
					content: "\e9a3";
				}

				.i-upload:before {
					content: "\eaa3";
				}

				.i-add:before {
					content: "\e9a4";
				}

				.i-t-shirt:before {
					content: "\eaa4";
				}

				.i-add-circle:before {
					content: "\e9a5";
				}

				.i-Up:before {
					content: "\eaa5";
				}

				.i-align-left:before {
					content: "\e9a6";
				}

				.i-suitcase:before {
					content: "\eaa6";
				}

				.i-address:before {
					content: "\e9a7";
				}

				.i-setting:before {
					content: "\eaa7";
				}

				.i-Arrow-Down:before {
					content: "\e9a8";
				}

				.i-user-add_1:before {
					content: "\eaa8";
				}

				.i-align-center:before {
					content: "\e9a9";
				}

				.i-user-edit:before {
					content: "\eaa9";
				}

				.i-Arrow-Left2:before {
					content: "\e9aa";
				}

				.i-user-add:before {
					content: "\eaaa";
				}

				.i-Arrow-down2:before {
					content: "\e9ab";
				}

				.i-unlock:before {
					content: "\eaab";
				}

				.i-arrow-go-forward:before {
					content: "\e9ac";
				}

				.i-user-prohibit:before {
					content: "\eaac";
				}

				.i-Arrow-Left:before {
					content: "\e9ad";
				}

				.i-Trademark:before {
					content: "\eaad";
				}

				.i-Arrow-Right:before {
					content: "\e9ae";
				}

				.i-user-right:before {
					content: "\eaae";
				}

				.i-aviation:before {
					content: "\e9af";
				}

				.i-thumb:before {
					content: "\eaaf";
				}

				.i-add_1:before {
					content: "\e9b0";
				}

				.i-vidicon-add:before {
					content: "\eab0";
				}

				.i-Arrow-Up2:before {
					content: "\e9b1";
				}

				.i-vidicon-minus:before {
					content: "\eab1";
				}

				.i-bag:before {
					content: "\e9b2";
				}

				.i-vidicon-download:before {
					content: "\eab2";
				}

				.i-bank:before {
					content: "\e9b3";
				}

				.i-table:before {
					content: "\eab3";
				}

				.i-align-right:before {
					content: "\e9b4";
				}

				.i-folder-download:before {
					content: "\eab4";
				}

				.i-bankcard:before {
					content: "\e9b5";
				}

				.i-voicemail:before {
					content: "\eab5";
				}

				.i-barchart:before {
					content: "\e9b6";
				}

				.i-user-minus:before {
					content: "\eab6";
				}

				.i-bike:before {
					content: "\e9b7";
				}

				.i-truck:before {
					content: "\eab7";
				}

				.i-bathroom:before {
					content: "\e9b8";
				}

				.i-voice-off:before {
					content: "\eab8";
				}

				.i-building:before {
					content: "\e9b9";
				}

				.i-voice:before {
					content: "\eab9";
				}

				.i-attachment:before {
					content: "\e9ba";
				}

				.i-wallet:before {
					content: "\eaba";
				}

				.i-Automotive:before {
					content: "\e9bb";
				}

				.i-volume-mute:before {
					content: "\eabb";
				}

				.i-Arrow-Right2:before {
					content: "\e9bc";
				}

				.i-volume-up:before {
					content: "\eabc";
				}

				.i-camera:before {
					content: "\e9bd";
				}

				.i-vidicon-upload:before {
					content: "\eabd";
				}

				.i-calendar:before {
					content: "\e9be";
				}

				.i-wechat:before {
					content: "\eabe";
				}

				.i-Bitcoin:before {
					content: "\e9bf";
				}

				.i-user-group:before {
					content: "\eabf";
				}

				.i-buy:before {
					content: "\e9c0";
				}

				.i-user:before {
					content: "\eac0";
				}

				.i-calendar-reservation:before {
					content: "\e9c1";
				}

				.i-weibo:before {
					content: "\eac1";
				}

				.i-calendar-check:before {
					content: "\e9c2";
				}

				.i-warning:before {
					content: "\eac2";
				}

				.i-barcode:before {
					content: "\e9c3";
				}

				.i-youtube:before {
					content: "\eac3";
				}

				.i-Arrow-Up:before {
					content: "\e9c4";
				}

				.i-zoom-in:before {
					content: "\eac4";
				}

				.i-alert:before {
					content: "\e9c5";
				}

				.i-wheelbarrow:before {
					content: "\eac5";
				}

				.i-arrow-go-back:before {
					content: "\e9c6";
				}

				.i-unlinks:before {
					content: "\eac6";
				}

				.i-bold:before {
					content: "\e9c7";
				}

				.i-vidicon:before {
					content: "\eac7";
				}

				.i-camera-off:before {
					content: "\e9c8";
				}

				.i-whats:before {
					content: "\eac8";
				}

				.i-cart-right:before {
					content: "\e9c9";
				}

				.i-volume-down:before {
					content: "\eac9";
				}

				.i-cart-minus:before {
					content: "\e9ca";
				}

				.i-Warehouse:before {
					content: "\eaca";
				}

				.i-cart-add:before {
					content: "\e9cb";
				}

				.i-zoom-out:before {
					content: "\eacb";
				}

				.i-card-sim:before {
					content: "\e9cc";
				}

				.i-message-talk:before {
					content: "\eacc";
				}

				.i-close:before {
					content: "\e9cd";
				}

				.i-mail-faild:before {
					content: "\eacd";
				}

				.i-certificate:before {
					content: "\e9ce";
				}

				.i-product-minus:before {
					content: "\eace";
				}

				.i-circle-minus:before {
					content: "\e9cf";
				}

				.i-phone-mobile:before {
					content: "\eacf";
				}

				.i-add-circle-Fill:before {
					content: "\e8d0";
				}

				.i-Circle-Right:before {
					content: "\e9d0";
				}

				.i-purchase:before {
					content: "\ead0";
				}

				.i-aviation-Fill:before {
					content: "\e8d1";
				}

				.i-card-id:before {
					content: "\e9d1";
				}

				.i-bag-Fill:before {
					content: "\e8d2";
				}

				.i-cloud:before {
					content: "\e9d2";
				}

				.i-address-Fill:before {
					content: "\e8d3";
				}

				.i-chart-pie:before {
					content: "\e9d3";
				}

				.i-Automotive-fill:before {
					content: "\e8d4";
				}

				.i-cloud-upload:before {
					content: "\e9d4";
				}

				.i-bank-Fill:before {
					content: "\e8d5";
				}

				.i-chat-smile:before {
					content: "\e9d5";
				}

				.i-barchart-Fill:before {
					content: "\e8d6";
				}

				.i-cloud-download:before {
					content: "\e9d6";
				}

				.i-bathroom-Fill:before {
					content: "\e8d7";
				}

				.i-Circle-down:before {
					content: "\e9d7";
				}

				.i-buy-Fill:before {
					content: "\e8d8";
				}

				.i-cart:before {
					content: "\e9d8";
				}

				.i-calendar-Fill:before {
					content: "\e8d9";
				}

				.i-Cosmetics1:before {
					content: "\e9d9";
				}

				.i-building-Fill:before {
					content: "\e8da";
				}

				.i-Circle-up:before {
					content: "\e9da";
				}

				.i-calendar-reservation-Fill:before {
					content: "\e8db";
				}

				.i-computer:before {
					content: "\e9db";
				}

				.i-bankcard-Fill:before {
					content: "\e8dc";
				}

				.i-crossborder:before {
					content: "\e9dc";
				}

				.i-alert-Fill:before {
					content: "\e8dd";
				}

				.i-cloud-faild:before {
					content: "\e9dd";
				}

				.i-Bitcoin-Fill:before {
					content: "\e8de";
				}

				.i-Customerservice:before {
					content: "\e9de";
				}

				.i-card-Fill:before {
					content: "\e8df";
				}

				.i-cloud-sucess:before {
					content: "\e9df";
				}

				.i-card-id-Fill:before {
					content: "\e8e0";
				}

				.i-coupon-2:before {
					content: "\e9e0";
				}

				.i-camera-Fill:before {
					content: "\e8e1";
				}

				.i-coupon:before {
					content: "\e9e1";
				}

				.i-cart-Fill:before {
					content: "\e8e2";
				}

				.i-doba:before {
					content: "\e9e2";
				}

				.i-cart-add-Fill:before {
					content: "\e8e3";
				}

				.i-compass:before {
					content: "\e9e3";
				}

				.i-bike-Fill:before {
					content: "\e8e4";
				}

				.i-diamond:before {
					content: "\e9e4";
				}

				.i-chart-pie-Fill:before {
					content: "\e8e5";
				}

				.i-Double-Left:before {
					content: "\e9e5";
				}

				.i-cart-minus-Fill:before {
					content: "\e8e6";
				}

				.i-distribution:before {
					content: "\e9e6";
				}

				.i-camera-Fill_1:before {
					content: "\e8e7";
				}

				.i-Double-up:before {
					content: "\e9e7";
				}

				.i-camera-off-Fill:before {
					content: "\e8e8";
				}

				.i-doller-2:before {
					content: "\e9e8";
				}

				.i-Circle-Right-Fill:before {
					content: "\e8e9";
				}

				.i-crown:before {
					content: "\e9e9";
				}

				.i-circle-minus-Fill:before {
					content: "\e8ea";
				}

				.i-crov:before {
					content: "\e9ea";
				}

				.i-Circle-Left-Fill:before {
					content: "\e8eb";
				}

				.i-doller-transfer:before {
					content: "\e9eb";
				}

				.i-certificate-Fill:before {
					content: "\e8ec";
				}

				.i-earth:before {
					content: "\e9ec";
				}

				.i-chat-smile-Fill:before {
					content: "\e8ed";
				}

				.i-Customers:before {
					content: "\e9ed";
				}

				.i-Circle-up-Fill:before {
					content: "\e8ee";
				}

				.i-exchange:before {
					content: "\e9ee";
				}

				.i-Circle-down-Fill:before {
					content: "\e8ef";
				}

				.i-facebook:before {
					content: "\e9ef";
				}

				.i-cart-right-Fill:before {
					content: "\e8f0";
				}

				.i-Dividends:before {
					content: "\e9f0";
				}

				.i-cloud-faild-Fill:before {
					content: "\e8f1";
				}

				.i-cpu:before {
					content: "\e9f1";
				}

				.i-coupon-2-Fill:before {
					content: "\e8f2";
				}

				.i-dashboard:before {
					content: "\e9f2";
				}

				.i-construction-Fill:before {
					content: "\e8f3";
				}

				.i-Down:before {
					content: "\e9f3";
				}

				.i-cloud-download-Fill:before {
					content: "\e8f4";
				}

				.i-download:before {
					content: "\e9f4";
				}

				.i-compass-Fill:before {
					content: "\e8f5";
				}

				.i-camera_1:before {
					content: "\e9f5";
				}

				.i-card-sim-Fill:before {
					content: "\e8f6";
				}

				.i-eye:before {
					content: "\e9f6";
				}

				.i-cloud-Fill:before {
					content: "\e8f7";
				}

				.i-file-caution:before {
					content: "\e9f7";
				}

				.i-coupon-Fill:before {
					content: "\e8f8";
				}

				.i-edit:before {
					content: "\e9f8";
				}

				.i-diamond-Fill:before {
					content: "\e8f9";
				}

				.i-file-png:before {
					content: "\e9f9";
				}

				.i-crossborder-Fill:before {
					content: "\e8fa";
				}

				.i-expense:before {
					content: "\e9fa";
				}

				.i-cloud-upload-Fill:before {
					content: "\e8fb";
				}

				.i-files-download:before {
					content: "\e9fb";
				}

				.i-Discountlabel:before {
					content: "\e8fc";
				}

				.i-edit-2:before {
					content: "\e9fc";
				}

				.i-exchange-Fill:before {
					content: "\e8fd";
				}

				.i-file-jpg:before {
					content: "\e9fd";
				}

				.i-doller-Fill:before {
					content: "\e8fe";
				}

				.i-file:before {
					content: "\e9fe";
				}

				.i-cruise-Fill:before {
					content: "\e8ff";
				}

				.i-file-ai:before {
					content: "\e9ff";
				}

				.i-cloud-sucess-Fill:before {
					content: "\e900";
				}

				.i-check:before {
					content: "\ea00";
				}

				.i-dashboard-Fill:before {
					content: "\e901";
				}

				.i-files-success:before {
					content: "\ea01";
				}

				.i-crown-Fill:before {
					content: "\e902";
				}

				.i-file-search_1:before {
					content: "\ea02";
				}

				.i-Cosmetics:before {
					content: "\e903";
				}

				.i-file-zip:before {
					content: "\ea03";
				}

				.i-computer-Fill:before {
					content: "\e904";
				}

				.i-doller:before {
					content: "\ea04";
				}

				.i-file-buyer-Fill:before {
					content: "\e905";
				}

				.i-file-gif:before {
					content: "\ea05";
				}

				.i-file-ai-Fill:before {
					content: "\e906";
				}

				.i-files-minus:before {
					content: "\ea06";
				}

				.i-earth-Fill:before {
					content: "\e907";
				}

				.i-euro:before {
					content: "\ea07";
				}

				.i-eye-Fill:before {
					content: "\e908";
				}

				.i-flag:before {
					content: "\ea08";
				}

				.i-file-jpg-Fill:before {
					content: "\e909";
				}

				.i-flask:before {
					content: "\ea09";
				}

				.i-euro-Fill:before {
					content: "\e90a";
				}

				.i-Dribbble:before {
					content: "\ea0a";
				}

				.i-file-right-Fill:before {
					content: "\e90b";
				}

				.i-folder-add:before {
					content: "\ea0b";
				}

				.i-direction-Fill:before {
					content: "\e90c";
				}

				.i-file-svg:before {
					content: "\ea0c";
				}

				.i-Customerservice-Fill:before {
					content: "\e90d";
				}

				.i-design:before {
					content: "\ea0d";
				}

				.i-file-search-Fill_1:before {
					content: "\e90e";
				}

				.i-doller-refund:before {
					content: "\ea0e";
				}

				.i-file-gif-Fill:before {
					content: "\e90f";
				}

				.i-file-prohibit:before {
					content: "\ea0f";
				}

				.i-file-caution-Fill:before {
					content: "\e910";
				}

				.i-folder-minus:before {
					content: "\ea10";
				}

				.i-eye-close-Fill:before {
					content: "\e911";
				}

				.i-cruise:before {
					content: "\ea11";
				}

				.i-file-prohibit-Fill:before {
					content: "\e912";
				}

				.i-folder-upload:before {
					content: "\ea12";
				}

				.i-files-invalid-Fill:before {
					content: "\e913";
				}

				.i-Fullscreen-Exit:before {
					content: "\ea13";
				}

				.i-file-search-Fill:before {
					content: "\e914";
				}

				.i-goblet:before {
					content: "\ea14";
				}

				.i-fire-Fill:before {
					content: "\e915";
				}

				.i-fridge:before {
					content: "\ea15";
				}

				.i-file-pdf-Fill:before {
					content: "\e916";
				}

				.i-Double-Right:before {
					content: "\ea16";
				}

				.i-edit-Fill:before {
					content: "\e917";
				}

				.i-google:before {
					content: "\ea17";
				}

				.i-filter-Fill:before {
					content: "\e918";
				}

				.i-guide:before {
					content: "\ea18";
				}

				.i-files-success-Fill:before {
					content: "\e919";
				}

				.i-gamepad:before {
					content: "\ea19";
				}

				.i-file-svg-Fill:before {
					content: "\e91a";
				}

				.i-file-search:before {
					content: "\ea1a";
				}

				.i-file-Fill:before {
					content: "\e91b";
				}

				.i-filter:before {
					content: "\ea1b";
				}

				.i-flask-Fill:before {
					content: "\e91c";
				}

				.i-file-buyer:before {
					content: "\ea1c";
				}

				.i-file-sync-Fill:before {
					content: "\e91d";
				}

				.i-home:before {
					content: "\ea1d";
				}

				.i-folder-add-Fill:before {
					content: "\e91e";
				}

				.i-hammer:before {
					content: "\ea1e";
				}

				.i-folder-Fill:before {
					content: "\e91f";
				}

				.i-files-invalid:before {
					content: "\ea1f";
				}

				.i-folder-upload-Fill:before {
					content: "\e920";
				}

				.i-eye-close:before {
					content: "\ea20";
				}

				.i-folder-download-Fill:before {
					content: "\e921";
				}

				.i-image-add:before {
					content: "\ea21";
				}

				.i-fridge-Fill:before {
					content: "\e922";
				}

				.i-file-pdf:before {
					content: "\ea22";
				}

				.i-files-add-Fill:before {
					content: "\e923";
				}

				.i-fire:before {
					content: "\ea23";
				}

				.i-folder-open-Fill:before {
					content: "\e924";
				}

				.i-info:before {
					content: "\ea24";
				}

				.i-file-png-Fill:before {
					content: "\e925";
				}

				.i-handsanitizer:before {
					content: "\ea25";
				}

				.i-gamepad-Fill:before {
					content: "\e926";
				}

				.i-font-color:before {
					content: "\ea26";
				}

				.i-gift-Fill:before {
					content: "\e927";
				}

				.i-files-add:before {
					content: "\ea27";
				}

				.i-files-download-Fill:before {
					content: "\e928";
				}

				.i-Landline:before {
					content: "\ea28";
				}

				.i-goblet-Fill:before {
					content: "\e929";
				}

				.i-file-sync:before {
					content: "\ea29";
				}

				.i-guide-Fill:before {
					content: "\e92a";
				}

				.i-Fullscreen:before {
					content: "\ea2a";
				}

				.i-hammer-Fill:before {
					content: "\e92b";
				}

				.i-hourglass:before {
					content: "\ea2b";
				}

				.i-flag-Fill:before {
					content: "\e92c";
				}

				.i-goblet_1:before {
					content: "\ea2c";
				}

				.i-files-minus-Fill:before {
					content: "\e92d";
				}

				.i-images:before {
					content: "\ea2d";
				}

				.i-hospital-Fill:before {
					content: "\e92e";
				}

				.i-leaf:before {
					content: "\ea2e";
				}

				.i-home-Fill:before {
					content: "\e92f";
				}

				.i-lightbulb:before {
					content: "\ea2f";
				}

				.i-file-zip-Fill:before {
					content: "\e930";
				}

				.i-googleplay:before {
					content: "\ea30";
				}

				.i-handsanitizer-Fill:before {
					content: "\e931";
				}

				.i-function:before {
					content: "\ea31";
				}

				.i-image-minus-Fill:before {
					content: "\e932";
				}

				.i-lock:before {
					content: "\ea32";
				}

				.i-image-Fill:before {
					content: "\e933";
				}

				.i-folder:before {
					content: "\ea33";
				}

				.i-Landline-Fill:before {
					content: "\e934";
				}

				.i-fingerprint:before {
					content: "\ea34";
				}

				.i-leaf-Fill:before {
					content: "\e935";
				}

				.i-gift:before {
					content: "\ea35";
				}

				.i-info-Fill:before {
					content: "\e936";
				}

				.i-mail-download:before {
					content: "\ea36";
				}

				.i-lightbulb-Fill:before {
					content: "\e937";
				}

				.i-list-unordered:before {
					content: "\ea37";
				}

				.i-maillist-Fill:before {
					content: "\e938";
				}

				.i-heart:before {
					content: "\ea38";
				}

				.i-Landline-2-Fill:before {
					content: "\e939";
				}

				.i-folder-open:before {
					content: "\ea39";
				}

				.i-lock-Fill:before {
					content: "\e93a";
				}

				.i-map:before {
					content: "\ea3a";
				}

				.i-goblet-Fill_1:before {
					content: "\e93b";
				}

				.i-card:before {
					content: "\ea3b";
				}

				.i-heart-Fill:before {
					content: "\e93c";
				}

				.i-mail:before {
					content: "\ea3c";
				}

				.i-function-fill:before {
					content: "\e93d";
				}

				.i-instagram_1:before {
					content: "\ea3d";
				}

				.i-mail-Fill:before {
					content: "\e93e";
				}

				.i-image:before {
					content: "\ea3e";
				}

				.i-mail-add-Fill:before {
					content: "\e93f";
				}

				.i-instagram:before {
					content: "\ea3f";
				}

				.i-folder-minus-Fill:before {
					content: "\e940";
				}

				.i-italic:before {
					content: "\ea40";
				}

				.i-mail-prohibit-Fill:before {
					content: "\e941";
				}

				.i-Left:before {
					content: "\ea41";
				}

				.i-message-Fill:before {
					content: "\e942";
				}

				.i-mail-prohibit:before {
					content: "\ea42";
				}

				.i-map-Fill:before {
					content: "\e943";
				}

				.i-message-report:before {
					content: "\ea43";
				}

				.i-hourglass-Fill:before {
					content: "\e944";
				}

				.i-live:before {
					content: "\ea44";
				}

				.i-mail-faild-Fill:before {
					content: "\e945";
				}

				.i-mail-add:before {
					content: "\ea45";
				}

				.i-live-Fill:before {
					content: "\e946";
				}

				.i-image-minus:before {
					content: "\ea46";
				}

				.i-music-Fill:before {
					content: "\e947";
				}

				.i-minus:before {
					content: "\ea47";
				}

				.i-image-add-Fill:before {
					content: "\e948";
				}

				.i-message-inquiry:before {
					content: "\ea48";
				}

				.i-message-talk-Fill:before {
					content: "\e949";
				}

				.i-outdoor:before {
					content: "\ea49";
				}

				.i-message-inquiry-Fill:before {
					content: "\e94a";
				}

				.i-message-problem:before {
					content: "\ea4a";
				}

				.i-palette-Fill:before {
					content: "\e94b";
				}

				.i-construction:before {
					content: "\ea4b";
				}

				.i-outdoor-Fill:before {
					content: "\e94c";
				}

				.i-MusicalInstruments:before {
					content: "\ea4c";
				}

				.i-message-problem-Fill:before {
					content: "\e94d";
				}

				.i-mail-right:before {
					content: "\ea4d";
				}

				.i-percent-Fill:before {
					content: "\e94e";
				}

				.i-income:before {
					content: "\ea4e";
				}

				.i-message-report-Fill:before {
					content: "\e94f";
				}

				.i-music:before {
					content: "\ea4f";
				}

				.i-mail-download-Fill:before {
					content: "\e950";
				}

				.i-Circle-Left:before {
					content: "\ea50";
				}

				.i-mail-right-Fill:before {
					content: "\e951";
				}

				.i-pause:before {
					content: "\ea51";
				}

				.i-phone-off-Fill:before {
					content: "\e952";
				}

				.i-phone:before {
					content: "\ea52";
				}

				.i-phone-mobile-Fill:before {
					content: "\e953";
				}

				.i-Pinterest:before {
					content: "\ea53";
				}

				.i-panorama-Fill:before {
					content: "\e954";
				}

				.i-links:before {
					content: "\ea54";
				}

				.i-phone-video-Fill:before {
					content: "\e955";
				}

				.i-Landline-2:before {
					content: "\ea55";
				}

				.i-oil-Fill:before {
					content: "\e956";
				}

				.i-omega:before {
					content: "\ea56";
				}

				.i-pause-Fill:before {
					content: "\e957";
				}

				.i-hospital:before {
					content: "\ea57";
				}

				.i-pet-fill:before {
					content: "\e958";
				}

				.i-file-right:before {
					content: "\ea58";
				}

				.i-product-caution-Fill:before {
					content: "\e959";
				}

				.i-fingerprintidentification:before {
					content: "\ea59";
				}

				.i-PricingPlan-Fill:before {
					content: "\e95a";
				}

				.i-printer:before {
					content: "\ea5a";
				}

				.i-phone-Fill:before {
					content: "\e95b";
				}

				.i-panorama:before {
					content: "\ea5b";
				}

				.i-play-Fill:before {
					content: "\e95c";
				}

				.i-pet:before {
					content: "\ea5c";
				}

				.i-plug-Fill:before {
					content: "\e95d";
				}

				.i-product-caution:before {
					content: "\ea5d";
				}

				.i-pencil-ruler-Fill:before {
					content: "\e95e";
				}

				.i-maillist:before {
					content: "\ea5e";
				}

				.i-printer-Fill:before {
					content: "\e95f";
				}

				.i-palette:before {
					content: "\ea5f";
				}

				.i-Productgroup-Fill:before {
					content: "\e960";
				}

				.i-minus_1:before {
					content: "\ea60";
				}

				.i-product-add-Fill:before {
					content: "\e961";
				}

				.i-product-returns:before {
					content: "\ea61";
				}

				.i-product-Fill:before {
					content: "\e962";
				}

				.i-percent:before {
					content: "\ea62";
				}

				.i-product-minus-Fill:before {
					content: "\e963";
				}

				.i-phone-video:before {
					content: "\ea63";
				}

				.i-product-prohibit-Fill:before {
					content: "\e964";
				}

				.i-product-right:before {
					content: "\ea64";
				}

				.i-product-right-Fill:before {
					content: "\e965";
				}

				.i-product-prohibit:before {
					content: "\ea65";
				}

				.i-calendar-check-Fill:before {
					content: "\e966";
				}

				.i-product-add:before {
					content: "\ea66";
				}

				.i-remind-Fill:before {
					content: "\e967";
				}

				.i-radio:before {
					content: "\ea67";
				}

				.i-question-Fill:before {
					content: "\e968";
				}

				.i-message:before {
					content: "\ea68";
				}

				.i-radio-Fill:before {
					content: "\e969";
				}

				.i-plug:before {
					content: "\ea69";
				}

				.i-service-2-Fill:before {
					content: "\e96a";
				}

				.i-oil:before {
					content: "\ea6a";
				}

				.i-product-returns-Fill:before {
					content: "\e96b";
				}

				.i-Reload-Right:before {
					content: "\ea6b";
				}

				.i-rmb-Fill:before {
					content: "\e96c";
				}

				.i-pencil-ruler:before {
					content: "\ea6c";
				}

				.i-security-Fill:before {
					content: "\e96d";
				}

				.i-phone-off:before {
					content: "\ea6d";
				}

				.i-shield-cross-Fill:before {
					content: "\e96e";
				}

				.i-remind:before {
					content: "\ea6e";
				}

				.i-share-Fill:before {
					content: "\e96f";
				}

				.i-Productgroup:before {
					content: "\ea6f";
				}

				.i-shield-Fill:before {
					content: "\e970";
				}

				.i-PricingPlan:before {
					content: "\ea70";
				}

				.i-setting-Fill:before {
					content: "\e971";
				}

				.i-question:before {
					content: "\ea71";
				}

				.i-shield-star-Fill:before {
					content: "\e972";
				}

				.i-paint:before {
					content: "\ea72";
				}

				.i-service-Fill:before {
					content: "\e973";
				}

				.i-Right:before {
					content: "\ea73";
				}

				.i-scales-Fill:before {
					content: "\e974";
				}

				.i-product:before {
					content: "\ea74";
				}

				.i-shop-2-Fill:before {
					content: "\e975";
				}

				.i-play:before {
					content: "\ea75";
				}

				.i-sofa-Fill:before {
					content: "\e976";
				}

				.i-service:before {
					content: "\ea76";
				}

				.i-shield-right-Fill:before {
					content: "\e977";
				}

				.i-service-2:before {
					content: "\ea77";
				}

				.i-sports-fill:before {
					content: "\e978";
				}

				.i-shield:before {
					content: "\ea78";
				}

				.i-skirt-fill:before {
					content: "\e979";
				}

				.i-Reload-Left:before {
					content: "\ea79";
				}

				.i-suitcase-Fill:before {
					content: "\e97a";
				}

				.i-repeat:before {
					content: "\ea7a";
				}

				.i-cpu-Fill:before {
					content: "\e97b";
				}

				.i-qrcode:before {
					content: "\ea7b";
				}

				.i-tag-Fill:before {
					content: "\e97c";
				}

				.i-save:before {
					content: "\ea7c";
				}

				.i-shop-Fill:before {
					content: "\e97d";
				}

				.i-shield-star:before {
					content: "\ea7d";
				}

				.i-thumb-Fill:before {
					content: "\e97e";
				}

				.i-scancode:before {
					content: "\ea7e";
				}

				.i-steak-Fill:before {
					content: "\e97f";
				}

				.i-rmb-transfer:before {
					content: "\ea7f";
				}

				.i-textile-Fill:before {
					content: "\e980";
				}

				.i-share-forward:before {
					content: "\ea80";
				}

				.i-distribution-Fill:before {
					content: "\e981";
				}

				.i-Refund:before {
					content: "\ea81";
				}

				.i-ticket-Fill:before {
					content: "\e982";
				}

				.i-sofa:before {
					content: "\ea82";
				}

				.i-shield-flash-Fill:before {
					content: "\e983";
				}

				.i-shield-right:before {
					content: "\ea83";
				}

				.i-trash-Fill:before {
					content: "\e984";
				}

				.i-rmb:before {
					content: "\ea84";
				}

				.i-t-shirt-Fill:before {
					content: "\e985";
				}

				.i-share:before {
					content: "\ea85";
				}

				.i-truck-Fill:before {
					content: "\e986";
				}

				.i-reply:before {
					content: "\ea86";
				}

				.i-Trademark-Fill:before {
					content: "\e987";
				}

				.i-security:before {
					content: "\ea87";
				}

				.i-toys:before {
					content: "\e988";
				}

				.i-rmb-refund:before {
					content: "\ea88";
				}

				.i-time-Fill:before {
					content: "\e989";
				}

				.i-subscript:before {
					content: "\ea89";
				}

				.i-trophy-Fill:before {
					content: "\e98a";
				}

				.i-shield-cross:before {
					content: "\ea8a";
				}

				.i-user-add-Fill_1:before {
					content: "\e98b";
				}

				.i-skirt:before {
					content: "\ea8b";
				}

				.i-unlock-Fill:before {
					content: "\e98c";
				}

				.i-shield-flash:before {
					content: "\ea8c";
				}

				.i-user-edit-Fill:before {
					content: "\e98d";
				}

				.i-Switch:before {
					content: "\ea8d";
				}

				.i-tariff-Fill:before {
					content: "\e98e";
				}

				.i-scales:before {
					content: "\ea8e";
				}

				.i-star-Fill:before {
					content: "\e98f";
				}

				.i-shop-2:before {
					content: "\ea8f";
				}`
			}
		},
		methods:{
			selectIcon(name){
				this.$emit('select',name);
				this.visible=false;
			}
		},
		created(){
			console.log(this.first);
			var reg=/(\.i\-.+)\:before/g;
			var ret=this.cssContent.match(reg);
			ret=ret.map(item=>{
				item=item.substr(1)
				item=item.substr(0,item.length-7);
				return item;
			})
			if(this.first){
				ret=ret.filter(item=>{
					return !this.first.includes(item);
				})
				ret=this.first.concat(ret);
				this.icons=ret;
				this.visible=true;
			}else{
				this.icons=ret;
				this.visible=true;	
			}
		},
		watch:{
			visible(val){
				if(!val){
					this.$parent.showIcon=false;
				}
			}
		}
	}
</script>