import Vue from 'vue'
import Vuex from 'vuex'
// import axios from '../utils/axios';
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
    	isLoaded:false,
    	menus:[],
    	user:{},
    	organization:{}
    },
    mutations: {
        update(state,[key,value]){
            state[key]=value;
        }
    }
})
export default store;